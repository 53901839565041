const hamburger = $("#hamburger");
const nav = $("#mobile-main-navigation");

export function openNav() {
  $("#mobile-main-navigation").removeClass("hidden");
  $("#hamburger .open").addClass("hidden");
  $("#hamburger .close").removeClass("hidden");
}

export function closeNav() {
  $("#mobile-main-navigation").addClass("hidden");
  $("#hamburger .close").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");
  $(".subnav, .subnav-close").addClass("hidden");
  $(".subnav-toggle, .menu-item").removeClass("hidden");
}
