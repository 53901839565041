import { openNav, closeNav } from "./modules/navigation.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $($("#mobile-main-navigation").hasClass("hidden") ? openNav() : closeNav());
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 1024) {
    closeNav();
  }
});

$(".subnav-toggle").click(function () {
  $(this).next(".subnav").removeClass("hidden");
  $(".subnav-close").removeClass("hidden");
  $(this).addClass("hidden");
  $(".menu-item").addClass("hidden");
});

$(".subnav-close").click(function () {
  $(".subnav, .subnav-close").addClass("hidden");
  $(".subnav-toggle, .menu-item").removeClass("hidden");
});

// Accordion
$(".accordion-toggle").click(function () {
  const accordion = $(this).parent();

  accordion.find(".accordion-body").slideToggle();
  $(this).find(".arrow").toggleClass("rotate-180");
});

// Carousels
var swiper = new Swiper(".testimonials", {
  loop: true,
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    bulletActiveClass: "swiper-pagination-bullet-active bg-black",
  },
});

var swiper = new Swiper(".carousel", {
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    bulletActiveClass: "swiper-pagination-bullet-active bg-white",
  },
});
